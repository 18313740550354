import React from "react"
import { useKey } from "react-use"
import { navigate } from "gatsby"
import PortableText from "./portable-text"

const Info = ({ content }) => {
  const {
    _rawInformation,
    address,
    contact,
    clients,
    follow,
    backgroundColor: bgColor,
    videoBackground: bgVideo,
  } = content

  const handleRedirect = () => {
    navigate("/")
  }

  useKey("Escape", handleRedirect)

  const textStyles = "font-sans text-xs leading-tight uppercase md:text-sm"
  const listStyles = "mb-2 font-serif text-lg"

  return (
    <div
      role="button"
      aria-label="Close page"
      className="w-full h-full cursor-close"
      style={{ backgroundColor: bgColor ? bgColor : "#FFFFFF" }}
      onClick={handleRedirect}
    >
      <div className="relative z-20 flex flex-col max-w-5xl min-h-screen px-6 pt-24 mx-auto">
        <div className="flex items-center justify-center flex-1">
          <div className="space-y-4 text-xs text-center uppercase md:text-base">
            {_rawInformation && <PortableText blocks={_rawInformation} />}
          </div>
        </div>
        <div className="flex flex-wrap w-full py-10 space-y-4 text-center sm:justify-between sm:text-left sm:space-y-0">
          <ul className="w-full sm:w-auto">
            <li className={listStyles}>Studio</li>
            <li className={`${textStyles} break-words whitespace-pre-line`}>
              {address}
            </li>
          </ul>
          <ul className="w-full sm:w-auto">
            <li className={listStyles}>Contact</li>
            <li className={textStyles}>
              <a href={`mailto:${contact}`}>{contact}</a>
            </li>
          </ul>
          <ul className="w-full sm:w-auto">
            <li className={listStyles}>Projects</li>
            {clients &&
              clients.map((client, i) => (
                <li key={i} className={textStyles}>
                  {client}
                </li>
              ))}
          </ul>
          <ul className="w-full sm:w-auto">
            <li className={listStyles}>Follow</li>
            <li className={textStyles}>
              <a href={follow} target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
      {bgVideo && (
        <div className="fixed inset-0 w-full h-screen overflow-hidden">
          <video
            src={bgVideo}
            className="object-cover w-full h-full"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          />
        </div>
      )}
    </div>
  )
}

export default Info
