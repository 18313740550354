import React from "react"
import { graphql } from "gatsby"
import GraphQLError from "../components/graphql-error"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Info from "../components/info"
import useSite from "../hooks/use-site"

export const query = graphql`
  query InfoPageQuery {
    info: sanityInfo {
      _rawInformation(resolveReferences: { maxDepth: 5 })
      address
      contact
      follow
      clients
      videoBackground
      backgroundColor
    }
  }
`

const InfoPage = ({ data, errors }) => {
  const site = useSite()

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    )
  }

  const { info } = data || {}
  if (!info) return null

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        url={site.url}
        keywords={site.keywords}
        image={site.image}
      />
      <Info content={info} />
    </Layout>
  )
}

export default InfoPage
